import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import FloatingSearch from "../../components/floatingSearch"
import sustainability_header from "../../../static/background_images/sustainability_header.jpg"
import ColoredHeader from "../../components/coloredHeader"
import { Row, Column } from "./_components"
import image2017 from "../../../static/sustainability/archives/EOG-SR-2017.png"
import image2018 from "../../../static/sustainability/archives/EOG-SR-2018.png"
import image2019 from "../../../static/sustainability/archives/EOG-SR-2019.png"
import image2020 from "../../../static/sustainability/archives/EOG-SR-2020.png"
import image2021 from "../../../static/sustainability/archives/EOG-SR-2021.png"

const EOG_2017_Sustainability_Report_PROD =
  "https://eogresources.s3-us-east-2.amazonaws.com/EOG_2017_Sustainability_Report_PROD.pdf"

const Sustainability_Report_2018 =
  "https://eogresources.s3-us-east-2.amazonaws.com/EOG_2018_Sustainability_Report.pdf"
const EOG_2019_Sustainability_Report_PROD =
  "https://eogresources.s3-us-east-2.amazonaws.com/EOG_2019_Sustainability_Report.pdf"
const EOG_2020_Sustainability =
  "https://eogresources.s3.us-east-2.amazonaws.com/EOG_2020_Sustainability_Report.pdf"

const EOG_2021_Sustainability =
  "https://eogresources.s3.us-east-2.amazonaws.com/EOG_2021_Sustainability_Report.pdf"

export default function SustainabilityArchives() {
  const themeColor = "#00c853"
  return (
    <Layout>
      <Helmet>Report Archives</Helmet>

      <ColoredHeader
        bgImageSRC={sustainability_header}
        title="SUSTAINABILITY"
        header="Report Archives"
        overlayColor1="#27a4a4"
        overlayColor2={themeColor}
        lineColor="rgb(0, 200, 83)"
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page sustainability-page last-page">
        <Row>
          <Column>
            <div className="archive-year">
              <a target="_none" href={EOG_2021_Sustainability}>
                <img src={image2021} alt="2021 Sustainability Report" />
              </a>
              <a
                target="_none"
                href={EOG_2021_Sustainability}
                className="archive-button"
              >
                2021 Sustainability Report
              </a>
            </div>
          </Column>
          <Column>
            <div className="archive-year">
              <a target="_none" href={EOG_2020_Sustainability}>
                <img src={image2020} alt="2020 Sustainability Report" />
              </a>
              <a
                target="_none"
                href={EOG_2020_Sustainability}
                className="archive-button"
              >
                2020 Sustainability Report
              </a>
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <div className="archive-year">
              <a target="_none" href={EOG_2019_Sustainability_Report_PROD}>
                <img src={image2019} alt="2019 Sustainability Report" />
              </a>
              <a
                target="_none"
                href={EOG_2019_Sustainability_Report_PROD}
                className="archive-button"
              >
                2019 Sustainability Report
              </a>
            </div>
          </Column>
          <Column>
            <div className="archive-year">
              <a target="_none" href={Sustainability_Report_2018}>
                <img src={image2018} alt="2018 Sustainability Report" />
              </a>
              <a
                target="_none"
                href={Sustainability_Report_2018}
                className="archive-button"
              >
                2018 Sustainability Report
              </a>
            </div>
          </Column>
        </Row>

        <Row>
          <Column>
            <div className="archive-year">
              <a target="_none" href={EOG_2017_Sustainability_Report_PROD}>
                <img src={image2017} alt="2017 Sustainability Report" />
              </a>
              <a
                target="_none"
                href={EOG_2017_Sustainability_Report_PROD}
                className="archive-button"
              >
                2017 Sustainability Report
              </a>
            </div>
          </Column>
        </Row>
      </div>
    </Layout>
  )
}
